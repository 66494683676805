* {
    box-sizing: border-box;
  }
  
  body {
    text-align: center;
    position: relative;
  }
  
  h1 {
    margin-top: 20px;
  }
  
  form {
    /*border: 1px solid black;*/
    max-width: 400px;
    padding: 10px;
    margin: auto;
    text-align: left;
  }
  
  label {
    line-height: 1.6;
    margin-left: 5px;
  }
  
  input {
    padding-left: 5px;
    width: 100%;
  }