.spieler {
    padding: 5px 10px;
    background-color: rgb(52, 58, 64);
    color: white;
    margin: 2px;
    border-radius: 4px;
    width: fit-content;
    float: left;
}

.close {
    padding-right: 10px;
}