.checked {
  text-decoration: line-through;
}

.current {
  border: 2px solid red;
  font-size: larger;
  padding-left: 10px;
  padding-right: 10px;
}

.current>* {
    margin-top: -2px;
}

.phase {
  /* margin-top: 10px;
    margin-bottom: 10px; */
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  position: relative;
  /* padding-top: 5px; */
}

.phase10 {
  margin: auto;
  width: fit-content;
}
