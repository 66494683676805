.add-spiel-button {
  margin: auto;
  margin-top: 5px;
  background-color: #ffffff;
  color: rgb(52, 58, 64);
}

.scoreboard {
  position: absolute;
  top: 112px;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom .p-scrollpanel-content {
    padding-bottom: 0px;
}

.config {
  position: absolute;
  top: 2px;
  right: 12px;
  padding: 7px;
  border: 1px solid rgb(52, 58, 64);
  border-radius: 5px;
  min-width: 40px;
}

.stats {
  position: absolute;
  top: 2px;
  left: 12px;
  padding: 7px;
  border: 1px solid rgb(52, 58, 64);
  border-radius: 5px;
  min-width: 40px;
}