li {
    list-style-type: none;
    margin-top: 15px;
}

.sport > table {
    width: 100%;
}

.sport > td, th {
    width: 50%;
}

tr {
    height: 40px;
}