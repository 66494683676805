.addSpiel {
  position: absolute;
  top: 60px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: whitesmoke;
  border: 1px solid darkgray;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
}

.eingabe {
  clear: both;
  width: fit-content;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  height: fit-content;
  /* border: 1px solid red; */
}

.label {
  /* float: left; */
  line-height: 38px;
  margin: auto;
  margin-top: 5px;
}

.toggle-button > input {
  display: none;
}

.toggle-button {
  margin: 0;
  margin-left: 5px;
  margin-top: 5px;
}

.btn {
  margin-top: 5px;
}

.anmerkung {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 6px 12px;
  /* color: rgb(255, 255, 255); */
  /* background-color: rgb(52, 58, 64); */
  color: rgb(52, 58, 64);
  border-color: rgb(52, 58, 64);
}

.p-scrollpanel-bar {
  display: none;
}

.p-scrollpanel {
  /* border: 1px solid red; */
}
