.App {
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.content {
  margin-top: 20px;
}

p {
  padding: 5px 20px;
  hyphens: auto;
  text-align: justify;
}

h1 {
  font-size: 2em;
}

html, body, #root, .App {
  height: 100%;
  position: relative;
}

.form {
  margin: 5px;
}